import { isJapanese } from 'wanakana'

const filterTest = (anItem, {
    kanji,
    questionType,
    allVocab,
    VOCAB_VALUE,
    KANJI_VALUE
}) => {
    if (!anItem[kanji] || !isJapanese(anItem[kanji])) return false
    if (allVocab) return true
    if ([VOCAB_VALUE, KANJI_VALUE].includes(anItem[questionType])) return true
    return false
}

const determineCardType = (str, {
    allVocab,
    kanjiValue,
    vocabValue
}) => {
    if (allVocab) return 'v'
    if (kanjiValue) return 'k'
    if (vocabValue) return 'v'
    return null
}

const removeDuplicates = (arr) => Array.from(new Set(arr))

// create a custom-formatted list consisting of an {k, t, q}[]
export default ({
    kanji,
    questionType,
    allVocab,
    vocabValue: VOCAB_VALUE,
    kanjiValue: KANJI_VALUE,
    queueState: QUEUE_STATE,
}, data = [], debug = false) => {
    if (debug) {
        console.log(kanji, questionType, allVocab, VOCAB_VALUE, KANJI_VALUE, QUEUE_STATE)
    }
    const items = data.filter((anItem) => filterTest(anItem, {
        allVocab,
        kanji,
        KANJI_VALUE,
        questionType,
        VOCAB_VALUE
    }))
        .map((anItem) => {
            const itemKanjiVal = anItem[kanji]
            const questionTypeVal = anItem[questionType]
            return {
                // dictionary entry
                k: itemKanjiVal,
                // question type ('k' or 'v')
                t: determineCardType(questionTypeVal, { allVocab, KANJI_VALUE, VOCAB_VALUE }),
                // queue state (0 or 10)
                q: parseInt(QUEUE_STATE, 10)
            }
        })
    return removeDuplicates(items)
}
