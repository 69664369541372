// return a matrix based on a split-apart csv data
export default ({
    fieldDelimiter = ';',
    lineDelimiter = /;\n/
}, data = [], debug = false) => {
    try {
        // split into lines, then split lines into cells
        return data.split(lineDelimiter)
        .map((aLine) => aLine.split(fieldDelimiter))
    } catch (e) {
        return null
    }
}
