<template>
    <div class="row">
        <div id="side" class="col-12 col-md-3">
            <div id="book_sidebar" class="row">
                <div class="col col-md-12 module">
                    <h2>Field Settings</h2>
                    <div class="marg-big row">
                        <label class="col-4">List Style</label>
                        <select class="col-6" v-model="processDataOptions.queueState">
                            <option value="0">Lessons</option>
                            <option value="99">Known Words</option>
                        </select>
                    </div>
                    <div class="marg-big row">
                        <label class="col-4">Word Field #</label>
                        <input class="col-6" v-model="processDataOptions.kanji" />
                    </div>
                    <div class="marg-big row">
                        <label class="col-4">Import Type</label>
                        <select class="col-6" v-model="processDataOptions.allVocab" @change="resetAllVocab()">
                            <option :value="true">All Vocabulary</option>
                            <option :value="false">Vocabulary & Kanji</option>
                        </select>
                    </div>
                    <div
                        v-if="!processDataOptions.allVocab"
                        class="marg-big row"
                    >
                        <label class="col-4">Question Type Field #</label>
                        <input class="col-6" v-model="processDataOptions.questionType" />
                    </div>
                    <div
                        v-if="!processDataOptions.allVocab"
                        class="marg-big row"
                    >
                        <label class="col-4">Vocabulary Marked As</label>
                        <input class="col-4" v-model="processDataOptions.vocabValue" />
                    </div>
                    <div
                        v-if="!processDataOptions.allVocab"
                        class="marg-big row"
                    >
                        <label class="col-4">Kanji Marked As</label>
                        <input class="col-4" v-model="processDataOptions.kanjiValue" />
                    </div>
                </div>
            </div>
        </div>
        <div id="main" class="col-12 col-md-9">
            <div class="thing-bod book-card pad-big">
                <h2>Data Preview</h2>
                <div class="marg-big">
                    The data below can be used to help assert the accuracy of the scan. This will show a <strong>maximum of {{maxRows}} rows</strong>.
                </div>
                <div class="marg-big">
                    <div
                        v-for="(items, indx) in activeWarnings"
                        :key="indx+'warn'"
                    ><i class="fas fa-exclamation-triangle c-red"></i> {{items}}
                    </div>
                </div>
                <div class="table-holder">
                    <table class="table table-striped marg-big">
                        <thead>
                            <tr>
                                <th>Row #</th>
                                <th
                                    v-for="(items, indx) in columns"
                                    :key="indx+'c'"
                                    scope="col"
                                >{{isActiveColumn(items, indx)}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(row, indx) in dat.slice(0, maxRows)"
                                :key="indx+'a'"
                            >
                                <th scope="row">{{indx}}</th>
                                <td
                                    v-for="(items, colN) in row"
                                    :key="colN+'r'"
                                    :class="isActiveField(items, colN)"
                                >{{items}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- warnings -->
                <!-- button -->
                <button
                class="btn bg-primary col-12"
                @click="generate()"
                :disabled="buttonStatus.disabled ? true : false"
                >{{buttonStatus.text}}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Table",
    data() {
        return {
            maxRows: 100,
            processDataOptions: {
                questionType: null, //  field #
                allVocab: true,
                vocabValue: 'v',
                kanjiValue: 'k',
                kanji: null, // field #
                queueState: "99"
            }
        }
    },
    props: [
        'dat'
    ],
    methods: {
        isActiveColumn(items, colN) {
            const { processDataOptions } = this
            // eslint-disable-next-line eqeqeq
            if (processDataOptions.kanji && colN == parseInt(processDataOptions.kanji, 10)) return 'WORD'
            // eslint-disable-next-line eqeqeq
            if (processDataOptions.questionType && parseInt(colN, 10) === parseInt(processDataOptions.questionType, 10)) {
                return 'Q TYPE'
            }
            return colN
        },
        isActiveField(items, colN) {
            const { processDataOptions } = this
            // eslint-disable-next-line eqeqeq
            if (processDataOptions.kanji && colN == parseInt(processDataOptions.kanji, 10)) return 'bg-success'
            // eslint-disable-next-line eqeqeq
            if (processDataOptions.questionType && colN === parseInt(processDataOptions.questionType, 10)) {
                if (items === processDataOptions.vocabValue) return 'bg-purple'
                if (items === processDataOptions.kanjiValue) return 'bg-danger'
            }
            return ''
        },
        resetAllVocab() {
            if (this.processDataOptions.allVocab === true) {
                this.processDataOptions.questionType = ''
            }
        },
        generate() {
            this.$emit('generate-list', this.processDataOptions)
        }
    },
    computed: {
        columns() {
            try {
                return this.dat[0]
            } catch (e) {
                console.warn(`error in table columns()`, e)
                return []
            }
        },
        activeWarnings() {
            const warnings = []
            const variableArrayLength = (arr) => {
                const len = arr[0].length
                const found = arr.slice(1, 9999).find((i) => i.length !== len)
                if (found) console.warn('Variable Array Length Warning found', found)
                if (!found) return null
                return {
                    expected: len,
                    received: found.length
                }
            }
            const vocabKanjiValueCheck = (arr, opts) => {
                const { vocabValue, kanjiValue, questionType } = opts
                const found = arr.slice(1, 9999).find((i) => i[questionType] !== vocabValue && i[questionType] !== kanjiValue)
                if (found) console.warn('Variable Array Length Warning found', found)
                if (!found) return null
                return {
                    expected: `${vocabValue} or ${kanjiValue}`,
                    received: found[questionType] || "<blank>"
                }
            }

            const warning1 = variableArrayLength(this.dat)
            if (warning1) warnings.push(`Some rows had more fields than others. Expected: ${warning1.expected} and found: ${warning1.received}`)
            if (!this.processDataOptions.allVocab && this.processDataOptions.questionType) {
                const warning2 = vocabKanjiValueCheck(this.dat, this.processDataOptions)
                if (warning2) warnings.push(`Some question type rows had unexpected values. Expected: ${warning2.expected} and found: ${warning2.received}. These rows will be ignored.`)
            }
            return warnings
        },
        buttonStatus() {
/* Generate processDataOptions.queueState == 0 ? 'Lessons' : 'Known Words' List Using Current Field Settings
        } */
            const { kanji } = this.processDataOptions
            if (isNaN(parseInt(kanji, 10))) {
                return {
                    text: `Please use the 'Field Settings' to set the 'Word Field #' to the column matching the kanji`,
                    disabled: true,
                }
            }
            const text = `Generate ${this.processDataOptions.queueState === 0 ? 'lessons' : 'known words'} list using 'Field Settings' options`
            return {
                disabled: false,
                text,
            }
        }
    }
}
</script>

<style lang="sass" scoped>
.bg-purple
    background-color: #7928a7
.thing-bod
    overflow-x: auto
.table-holder
    font-size: .8rem
    max-height: 300px
    overflow-y: auto
table.table
    th
        min-width: 8em
    .bg-danger, .bg-success, .bg-primary, .bg-purple
        color: white
</style>
