<template>
    <div class="row">
        <div id="side" class="col-12 col-md-3">
            <div id="book_sidebar" class="row">
                <div class="col col-md-12 module">
                    <h2>Preset</h2>
                    <RawBtn
                    class="btn bg-info marg-small"
                    @click.native="presetHouhou()">Houhou</RawBtn>
                    <br />
                    <RawBtn
                    class="btn bg-info marg-small"
                    @click.native="presetAnki()">Anki</RawBtn>
                </div>
                <div class="col col-md-12 module">
                    <h2>Options</h2>
                    <div class="marg-big row">
                        <label class="col-4">Line Delimiter</label>
                        <input class="col-6" v-model="processDataOptions.lineDelimiter" />
                    </div>
                    <div class="marg-big row">
                        <label class="col-4">Field Delimiter</label>
                        <input class="col-6" v-model="processDataOptions.fieldDelimiter" />
                    </div>
                    <div v-if="0 == 1" class="marg-big row">
                        <label class="col-4">Field Encapsulator</label>
                        <input class="col-6" v-model="processDataOptions.fieldEncapsulator" />
                    </div>
                </div>
            </div>
        </div>
        <div id="main" class="col-12 col-md-9">
            <div class="book-card pad-big marg-big">
                <h2>Importer</h2>
                <div class="marg-big">
                    <div><i class="fas fa-exclamation-triangle c-red"></i> Please remove any header rows</div>
                    <div><i class="fas fa-exclamation-triangle c-red"></i> Common <strong>row (line) delimiters</strong> include: \n (new line) \r\n (also new line)</div>
                    <div><i class="fas fa-exclamation-triangle c-red"></i> Common <strong>field delimiters</strong> include: \t (tab), and semicolons</div>
                </div>
                <textarea
                    id="import_area"
                    class="col-12"
                    placeholder="Paste csv data here"
                    v-model="text"
                ></textarea>
                <RawBtn class="btn col-12"
                :class="`${buttonStatus}`"
                @click.native="process()"
                >{{text === ''
                ? `Please enter some data`
                : `Scan data using current delimiter settings`}}</RawBtn>
            </div>
        </div>
    </div>
</template>

<script>
import {
    RawBtn,
} from './index'

export default {
    name: "Raw",
    components: {
        RawBtn
    },
    data() {
        return {
            text: "",
            processDataOptions: {
                fieldDelimiter: ';',
                lineDelimiter: '\\n',
                fieldEncapsulator: '',
            }
        }
    },
    methods: {
        process() {
            const { fieldDelimiter, lineDelimiter, fieldEncapsulator } = this.processDataOptions
            const regexFromString = (string) => {
                const match = /^\/(.*)\/([a-z]*)$/.exec(string)
                console.log('123', match)
                return new RegExp(match[1], match[2])
            }
            this.$emit('process-text', {
                lineDelimiter: regexFromString(`/${lineDelimiter}/g`),
                fieldDelimiter: !fieldEncapsulator
                               ? regexFromString(`/${fieldDelimiter}/g`)
                               : regexFromString(`/${fieldEncapsulator}(.*?)${fieldEncapsulator}${fieldDelimiter}/g`)
            })
        },
        presetHouhou() {
            const name = 'Houhou'
            this.processDataOptions.fieldDelimiter = ';'
            this.processDataOptions.lineDelimiter = ';\\n'
            this.processDataOptions.fieldEncapsulator = ''
            this.$emit('send-alert', { msg: `Set field and line delimiters to ${name} defaults`, style: 'alert-success' })
        },
        presetAnki() {
            const name = 'Anki'
            this.processDataOptions.fieldDelimiter = '\\t'
            this.processDataOptions.lineDelimiter = '\\n'
            this.processDataOptions.fieldEncapsulator = ''
            this.$emit('send-alert', { msg: `Set field and line delimiters to ${name} defaults`, style: 'alert-success' })
        }
    },
    computed: {
        buttonStatus() {
            const val = this.text
            if (!val) return 'bg-secondary'
            return 'bg-primary'
        }
    }
}
</script>
