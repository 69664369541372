<template>
    <div id="importer" class="container-fluid">
        <div class="col-12 col-lg-11 col-xl-10 mx-auto spacer">
            <!-- scanner -->
            <Raw v-on:send-alert="sendAlert($event)" v-on:process-text="mainFlow($event)" />
            <!-- interface -->
            <template v-if="processedData">
                <Table v-if="processedData" :dat="processedData" v-on:generate-list="secondFlow($event)" />
            </template>
            <template v-if="producedList">
                <Final :list="producedList" v-on:generate-api-request="alert('done')" />
            </template>
        </div>
    </div>
</template>

<script>
import C from '@/assets/common'
import { Raw } from './Raw/index'
import Table from './Table.vue'
import Final from './Final.vue'
import {
    processData,
    createList
} from '@/features/csv/index'

const {
    SendUserAlert
} = C

export default {
    name: 'Import',
    components: {
        Raw,
        Table,
        Final
    },
    data() {
        return {
            dat: "",
            processedData: null,
            producedList: null,
        }
    },
    methods: {
        sendAlert(e) {
            const { msg, style } = e
            SendUserAlert(msg, style)
        },
        mainFlow({
            fieldDelimiter = ';',
            lineDelimiter = /;\n/
        }) {
            // get text from text area
            const textValue = this.processText()
            if (!textValue) {
                SendUserAlert('No value received', 'alert-warning')
                return
            }
            // store value for debugging
            this.dat = textValue
            // process into usable format
            this.processedData = this.processData({
                fieldDelimiter,
                lineDelimiter
            }, textValue)
        },
        secondFlow(eventData) {
            this.producedList = this.createList(eventData, this.processedData, true)
        },
        processText() {
            const val = document.getElementById('import_area').value
            if (val) return val
            return null
        },
        processData,
        createList,
    },
    computed: {
    }
}
</script>

<style lang="sass">
#importer
    .spacer
        margin-bottom: 12rem
    textarea
        min-height: 400px
</style>
