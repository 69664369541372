<template>
    <div class="row">
        <div id="side" class="col-12 col-md-3">
        </div>
        <div id="main" class="col-12 col-md-9">
            <div class="thing-bod book-card pad-big marg-big">
                <h2>Data Preview</h2>
                <div class="marg-big">
                    The data below can be used to help assert the accuracy of the scan. This will show a <strong>maximum of {{maxRows}} rows</strong>.
                </div>
                <div class="marg-big">
                    <div
                        v-for="(items, indx) in activeWarnings"
                        :key="indx+'warn'"
                    ><i class="fas fa-exclamation-triangle c-red"></i> {{items}}
                    </div>
                </div>
                <div
                    v-if="this.list.length > 0"
                    class="table-holder"
                >
                    <table class="table table-striped marg-big">
                        <thead>
                            <tr>
                                <th>Row #</th>
                                <th>Word</th>
                                <th>Card Type</th>
                                <th>SRS Level</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(row, indx) in list.slice(0, maxRows)"
                                :key="indx+'a'"
                            >
                                <th scope="row">{{indx}}</th>
                                <td>{{row['k']}}</td>
                                <td>{{row['t']}}</td>
                                <td>{{row['q']}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <button
                    class="btn col-12"
                    :class="`${buttonColor} `"
                    @click="sendToServer()"
                >Send to Server</button>
            </div>
            <div
                v-if="serverResponse"
                class="thing-bod book-card pad-big marg-big"
            >
                <h2>Server Response</h2>
                <div>{{serverResponse}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import C from '@/assets/common'

const {
    UseAPI, HandleRequestFail
} = C

export default {
    name: 'Final',
    props: [
        'list',
    ],
    data() {
        return {
            maxRows: 150,
            awaitingResponse: false,
            serverResponse: null
        }
    },
    computed: {
        buttonColor() {
            return this.awaitingResponse ? 'bg-secondary' : 'bg-primary'
        },
        activeWarnings() {
            const warnings = []
            if (this.list.length < 1) warnings.push(`No valid data to import to server`)
            return warnings
        }
    },
    methods: {
        sendToServer() {
            if (this.awaitingResponse) {
                alert('Awaiting server response')
                return
            }
            let currentPosition = 0
            const positionStep = 1500
            this.awaitingResponse = true
            this.serverResponse = null
            const loopBody = () => {
                const body = { items: this.list.slice(currentPosition, currentPosition + positionStep) }
                return UseAPI('/create/import-known', { method: "PUT", body: JSON.stringify(body) })
                .then((dat) => {
                    this.serverResponse = this.serverResponse
                                            ? [...this.serverResponse, dat]
                                            : [dat]
                })
                .catch((dat) => {
                    console.warn('Error with /create/import-known/')
                    HandleRequestFail(dat)
                    console.warn(dat.msg)
                    console.warn(JSON.stringify(dat.e))
                    this.serverResponse = dat
                })
                .then(() => {
                    //  clear unknown words cache
                    localStorage.removeItem('all-unknown')
                })
            }
            const loop = () => {
                loopBody()
                .then(() => {
                    currentPosition += positionStep
                    if (currentPosition < this.list.length) {
                        loop()
                        return
                    }
                    this.awaitingResponse = false
                })
            }
            loop()
        }
    }
}
</script>

<style lang="sass" scoped>
.bg-purple
    background-color: #7928a7
.thing-bod
    overflow-x: auto
.table-holder
    font-size: .8rem
    max-height: 300px
    overflow-y: auto
table.table
    th
        min-width: 8em
    .bg-danger, .bg-success, .bg-primary, .bg-purple
        color: white
</style>
